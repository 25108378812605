import React, { useState, useEffect } from 'react'
import './push-up.css'

import scrollTopArrow from './../../assets/img/scroll_top_arrow.svg'

const PushUp = () => {
  const [showPushUp, setShowPushUp] = useState(false)

  const pushUp = () => {
    const scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop
    setShowPushUp(scrollPosition >= 500)
  }

  useEffect(() => {
    window.addEventListener('scroll', pushUp)
    return () => window.addEventListener('scroll', pushUp)
  }, [])

  const scrollMeTo = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <>
      <div onClick={scrollMeTo} className={`scroll_top ${!showPushUp ? 'disabled' : ''}`}>
        <img
          draggable='false' src={scrollTopArrow}
          alt='Flèche bleue orientée vers le haut'
        />
      </div>
    </>
  )
}

export default PushUp
