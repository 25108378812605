import React from 'react'
import { Link } from 'gatsby'

import './footer.css'

import footerEmblem from '../../assets/img/footer_emblem.svg'
import footerLogo from '../../assets/img/footer_logo.svg'
import facebook from '../../assets/img/facebook.svg'
import twitter from '../../assets/img/twitter.svg'
import linkedin from '../../assets/img/linkedin.svg'
import youtube from '../../assets/img/youtube.svg'
import instagram from '../../assets/img/instagram.svg'
import snapchat from '../../assets/img/snapchat.svg'

const Footer = () => {
  return (
    <footer>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3 emblem_footer'>
            <img src={footerEmblem} alt='Emblème Paramedic' draggable='false' />
          </div>
          <div className='col-lg-9 right'>
            <div className='col-lg-12'>
              <Link to='/'><img src={footerLogo} alt='Logo Complet Paramedic' draggable='false' /></Link>
            </div>
            <div className='row list_footer'>
              <div className='col-lg-4'>
                <ul>
                  <li><Link to='/'>Concept</Link></li>
                  <li><Link to='/interface'>Interface</Link></li>
                  <li><Link to='/avantages'>Avantages</Link></li>
                  <li><Link to='/ecologique'>Impact écologique</Link></li>
                  <li><Link to='/art80'>Article 80</Link></li>
                  <li><Link to='/souscription'>Souscription</Link></li>
                  <li><Link to='/contact'>Contact</Link></li>
                </ul>
              </div>
              <div className='col-lg-8'>
                <ul>
                  <li className='show_mobile'>
                    <a href='https://www.facebook.com/Paramedic-Paris-131116847579307/'>
                      <img
                        draggable='false'
                        src={facebook}
                        alt='Logo Facebook'
                      />
                    </a>
                    <a href='https://twitter.com/ParamedicParis'>
                      <img
                        draggable='false'
                        src={twitter}
                        alt='Logo Twitter'
                      />
                    </a>
                    <a href='https://www.linkedin.com/in/paramedic-tech-a3a58a159/'>
                      <img
                        draggable='false'
                        src={linkedin}
                        alt='Logo LinkedIn'
                      />
                    </a>
                    <a href='https://www.youtube.com/channel/UCpe_ByKm1u_mmR7zla9PEyg'>
                      <img
                        draggable='false'
                        src={youtube}
                        alt='Logo YouTube'
                      />
                    </a>
                    <a href='https://www.instagram.com/paramedic.tech'>
                      <img
                        draggable='false'
                        src={instagram}
                        alt='Logo Instagram'
                      />
                    </a>
                    {/* <a href='https://www.snapchat.com/add/paramedicparis'>
                      <img
                        draggable='false'
                        src={snapchat}
                        alt='Logo Snapchat'
                      />
                    </a> */}
                  </li>
                  <li><Link to='/fondateurs'>Les fondateurs</Link></li>
                  <li><Link to='/confidentialite'>Politique de conﬁdentialité</Link></li>
                  <li><Link to='/mentions-legales'>Mentions légales</Link></li>
                  <li><Link to='/cgu'>CGU</Link></li>
                  <li className='hide_mobile'>
                    <a href='https://www.facebook.com/Paramedic-Paris-131116847579307/'>
                      <img
                        draggable='false'
                        src={facebook}
                        alt='Logo Facebook'
                      />
                    </a>
                    <a href='https://twitter.com/ParamedicParis'>
                      <img
                        draggable='false'
                        src={twitter}
                        alt='Logo Twitter'
                      />
                    </a>
                    <a href='https://www.linkedin.com/in/paramedic-tech-a3a58a159/'>
                      <img
                        draggable='false'
                        src={linkedin}
                        alt='Logo LinkedIn'
                      />
                    </a>
                    <a href='https://www.youtube.com/channel/UCpe_ByKm1u_mmR7zla9PEyg'>
                      <img
                        draggable='false'
                        src={youtube}
                        alt='Logo YouTube'
                      />
                    </a>
                    <a href='https://www.instagram.com/paramedic.tech/'>
                      <img
                        draggable='false'
                        src={instagram}
                        alt='Logo Instagram'
                      />
                    </a>
                    {/* <a href='https://www.snapchat.com/add/paramedicparis'>
                      <img
                        draggable='false'
                        src={snapchat}
                        alt='Logo Snapchat'
                      />
                    </a> */}
                  </li>
                </ul>
              </div>
              <div className='col-md-12 copyright-container'>
                <p className='copyright' style={{ textAlign: 'center' }}>&copy;
                  {new Date().getFullYear()}{' '}
                  Paramedic. Premier service de gestion de transports sanitaires en France.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
