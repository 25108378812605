import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PushUp from '../components/pushUp'
import { Helmet } from 'react-helmet'

const Layout = ({ children }) => {
  return (
    <div id='app'>
      <Helmet>
        <script type='text/javascript' src='https://static.eversign.com/js/embedded-signing.js' />
      </Helmet>
      <Header />
      {children}
      <PushUp />
      <Footer />
    </div>
  )
}

export default Layout
