import React, { useState } from 'react'
import { Link } from 'gatsby'

import './header.css'

import logoMobileMenu from '../../assets/img/logo_mobile_menu.svg'
import facebook from '../../assets/img/facebook.svg'
import twitter from '../../assets/img/twitter.svg'
import linkedin from '../../assets/img/linkedin.svg'
import youtube from '../../assets/img/youtube.svg'
import instagram from '../../assets/img/instagram.svg'
import snapchat from '../../assets/img/snapchat.svg'
import logoHeader from '../../assets/img/logo_header.svg'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  // console.log(menuOpen)

  return (
    <>
      <header id='header'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3'>
              <Link to='/'>
                <img
                  src={logoHeader}
                />
              </Link>
            </div>
            <div className='col-lg-7'>
              <ul>
                <li><Link to='/'>Concept</Link></li>
                <li><Link to='/interface'>Interface</Link></li>
                <li><Link to='/avantages'>Avantages</Link></li>
                <li><Link to='/ecologique'>Impact écologique</Link></li>
                <li><Link to='/art80'>Article 80</Link></li>
                <li><Link to='/souscription'>Souscription</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
              </ul>
            </div>
            <div className='col-lg-2'>
              <a href=''>Français</a>
            </div>
          </div>
        </div>
      </header>

      <div className='mobile_menu_container container-fluid'>
        <Link to='/'>
          <img
            src={logoMobileMenu} alt='Paramedic logo header'
            draggable='false'
          />
        </Link>
      </div>

      <div className='menu-wrap'>
        <input type='checkbox' onChange={() => setMenuOpen(!menuOpen)} checked={menuOpen} className='toggler' />
        <div className='hamburger'>
          <div />
        </div>
        <div className='menu'>
          <div>
            <div>
              <ul>
                <Link to='/'>
                  <img
                    src={logoMobileMenu} alt='Paramedic logo header'
                    className='logo_responsive_menu'
                    draggable='false'
                  />
                </Link>
                <li />
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/'>Concept</Link></li>
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/interface'>Interface</Link></li>
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/avantages'>Avantages</Link></li>
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/ecologique'>Impact écologique</Link></li>
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/art80'>Article 80</Link></li>
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/souscription'>Souscription</Link></li>
                <li><Link onClick={() => setMenuOpen(!menuOpen)} to='/contact'>Contact</Link></li>
                <li>
                  <a href='https://www.facebook.com/Paramedic-Paris-131116847579307/'>
                    <img
                      draggable='false'
                      src={facebook}
                      alt='Logo Facebook'
                    />
                  </a>
                  <a href='https://twitter.com/ParamedicParis?lang=fr'>
                    <img
                      draggable='false'
                      src={twitter}
                      alt='Logo Twitter'
                    />
                  </a>
                  <a href='https://www.linkedin.com/in/paramedic-tech-a3a58a159/'>
                    <img
                      draggable='false'
                      src={linkedin}
                      alt='Logo LinkedIn'
                    />
                  </a>
                  <a href='https://www.youtube.com/channel/UCpe_ByKm1u_mmR7zla9PEyg'>
                    <img
                      draggable='false' src={youtube} alt='Logo YouTube'
                    />
                  </a>
                  <a href='https://www.instagram.com/paramedic.tech/'>
                    <img
                      draggable='false'
                      src={instagram}
                      alt='Logo Instagram'
                    />
                  </a>
                  {/* <a href='https://www.snapchat.com/add/paramedicparis'>
                    <img
                      draggable='false'
                      src={snapchat}
                      alt='Logo Snapchat'
                    />
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
