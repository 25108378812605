import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollReveal from 'scrollreveal'
require('./node_modules/bootstrap/dist/css/bootstrap.css')

AOS.init()

ScrollReveal().reveal('.delay_100', {
  delay: 100
})
ScrollReveal().reveal('.delay_200', {
  delay: 200
})
ScrollReveal().reveal('.delay_250', {
  delay: 250
})
ScrollReveal().reveal('.delay_300', {
  delay: 300
})
ScrollReveal().reveal('.delay_400', {
  delay: 400
})
ScrollReveal().reveal('.delay_500', {
  delay: 500
})
ScrollReveal().reveal('.delay_600', {
  delay: 600
})
ScrollReveal().reveal('.delay_700', {
  delay: 700
})
ScrollReveal().reveal('.delay_750', {
  delay: 750
})
ScrollReveal().reveal('.delay_800', {
  delay: 800
})
ScrollReveal().reveal('.delay_900', {
  delay: 900
})
ScrollReveal().reveal('.delay_1000', {
  delay: 1000
})
ScrollReveal().reveal('.delay_1100', {
  delay: 1100
})
